.control-element {
    margin-inline: 25px;
    margin-block: 2px;
    white-space: nowrap;
    display: inline-block;
}

label.control-element input, label.control-element select {
    margin-left: 10px;
}

.react-toggle {
    margin-left: 10px;
    margin-right: 10px;
    vertical-align: middle;
}

form {
    margin-block: 8px;
}